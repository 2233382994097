<template>
    <div class="subNavigation">
        <div class="maxWidth">
            <a v-for="(section, i) in sections"
               :href="'#' + section.ref"
               :class="getActive(i)"
               @click.prevent="scrollTo">{{ section.text }}</a>
        </div>
    </div>
</template>
<script>
export default {
    props: ["sections"],
    data() {
        return {
            frame: null,
            currScroll: 0,
            anchorPositions: []
        }

    },

    mounted() {
        this.updateScroll()
        window.addEventListener("resize", this.getAnchorPositions)
        this.getAnchorPositions()
    },
    beforeUnmount() {
        cancelAnimationFrame(this.frame)
        window.removeEventListener("resize", this.getAnchorPositions)
    },
    methods: {
        getActive(i) {
            if (i + 1 == this.sections.length) {
                return {
                    active: this.currScroll >= this.anchorPositions[i]
                }
            }
            else {
                return {
                    active: this.currScroll >= this.anchorPositions[i] && this.currScroll < this.anchorPositions[i + 1]
                }
            }

        },
        scrollTo(e) {
            document.querySelector("#" + e.target.href.split("#")[1]).scrollIntoView({
                behavior: 'smooth'
            });
        },
        updateScroll() {
            this.frame = requestAnimationFrame(this.updateScroll.bind(this))
            this.currScroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        getAnchorPositions() {
            this.anchorPositions = []
            this.sections.forEach(s => {
                if (document.querySelector("#" + s.ref))
                    this.anchorPositions.push(
                        document.querySelector("#" + s.ref).getBoundingClientRect().top + this.currScroll - 10
                    )
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.subNavigation {
    height: $header-height;
    background-color: $altBlack;
    color: white;
    position: fixed;
    top: $header-height;
    width: 100%;
    z-index: 10;
    @include defaultFontSize;
}

.maxWidth {
    display: flex;
    justify-content: center;
    gap: 35px;

    a {
        height: $header-height;
        line-height: $header-height;
        color: white;
        font-weight: 500;
        position: relative;

        // &.active::after {
        //     content: "";
        //     display: block;
        //     width: 100%;
        //     height: 6px;
        //     background-color: $volt;
        //     position: absolute;
        //     bottom: 0px;
        // }
    }
}
</style>
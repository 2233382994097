<template>
  <span class="sync" :style="genColor">
    <sync-arrow />
  </span>
</template>

<script>
import SyncArrow from "./SpinnerSVG.vue";
export default {
  components: { SyncArrow },
  props: {
    spincolor: {
      type: String,
      default: "#cccccc",
    },
  },
  computed: {
    genColor() {
      return {
        color: this.spincolor,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.sync svg {
  transform-origin: 50% 50%;
  animation: rotate 2s linear 0s infinite forwards;
  transform: rotate(45deg);
  display: inline-block;
  width: 1em;
  height: 1em;
}
@keyframes rotate {
  from {
    transform: translateY(15%) rotate(0deg);
  }
  to {
    transform: translateY(15%) rotate(360deg);
  }
}
</style>
<template>
    <padder big>
        <div class="registrationContainer">
            <h2>{{ general.registration_intro }}</h2>
            <a :href="adminLink"
               class="registerLink">{{ general.registration_button }}</a>
            <replace-links :htmlContent="general.registration_extra"
                           class="extraInfo inlineText" />
        </div>
    </padder>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ReplaceLinks from './ReplaceLinks.vue';
export default {
    components: { ReplaceLinks },
    methods: {
        ...mapActions(["register"])
    }
}
</script>

<style lang="scss" scoped>
.registrationContainer {
    margin-top: 224px;
    margin-bottom: 235px;
}

h2 {
    text-align: center;
    max-width: 1100px;
    margin: 0px auto;
}

a.registerLink {
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    margin: 50px auto;
    @include titleFontSize;
    font-weight: 500;
    height: 95px;
    background-color: $volt;
    // line-height: 95px;
    text-align: center;
    border-radius: 6px;

    &:hover {
        background-color: $voltHover;
    }
}

.extraInfo {
    max-width: $reduced-width;
    margin: 0px auto;
    @include bigFontSize;
    text-align: center;
    font-weight: 500;
}


@media screen and (max-width: $breakpoint) {
    .registrationContainer {
        margin-top: 136px;
        margin-bottom: 166px;
    }

    a.registerLink {
        font-size: 30px;
        width: 310px;
    }

    .extraInfo {
        @include defaultFontSize;
    }
}
</style>
<template>
  <img
    v-if="img"
    :key="currLocale"
    class="lazy"
    :width="img.width"
    :height="img.height"
    :src="gensvg"
    :data-src="img.url"
    :data-srcset="srcset"
    :data-sizes="sizes"
    :alt="img.alt"
    :sizes="sizes"
  />
</template>

<script>
export default {
  props: ["img", "sizes", "superlazy"],
  computed: {
    gensvg() {
      // return `data:image/svg+xml;utf8,
      //       <svg xmlns='http://www.w3.org/2000/svg' style="background-color:lightgrey" viewBox='0 0 ${this.img.width} ${this.img.height}' height='${this.img.height}' width='${this.img.width}'>
      //           </svg>`;
      return this.img.sizes[0].src;
    },
    srcset() {
      var sizesString = "";
      for (var i = 0; i < this.img.sizes.length; i++) {
        sizesString +=
          this.img.sizes[i].src +
          " " +
          this.img.sizes[i].width +
          "w" +
          (i < this.img.sizes.length - 1 ? ", " : "");
      }
      return sizesString;
    },
  },
  watch: {
    superlazy: {
      handler() {
        if (this.superlazy != true) {
          this.$nextTick(() => {
            this.$store.state.lazy.update();
          });
        }
      },
      immediate: true,
    },
    img: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$store.state.lazy.update();
        });
      },
    },
    currLocale: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$store.state.lazy.update();
        });
      },
    },
  },
};
</script>

<template>
  <a
    :href="to"
    :class="{ greenButtonLink: true, autoWidth }"
    v-if="isNotRouterLink"
  >
    <slot></slot>
  </a>
  <router-link :to="to" v-else class="greenButtonLink">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  mounted() {},
  props: {
    to: { type: [Object, String] },
    autoWidth: { type: Boolean, default: false },
  },
  computed: {
    isNotRouterLink() {
      if (typeof this.to == "string") {
        if (this.to.charAt(0) == "/") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.greenButtonLink {
  background-color: $volt;
  border-radius: 3px;
  height: 33px;
  width: 210px;
  display: inline-block;
  line-height: 33px;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 0px auto;

  &.autoWidth {
    width: max-content;
    padding: 0px 20px;
  }

  &:hover {
    background-color: $voltHover;
  }
}
</style>

import axios from 'axios'
export default {
    install: (app, options) => {
        var ax = axios.create({
            baseURL: options.baseUrl,
            headers: {
                Authorization: options.token ? options.token : '',
            }
        })
        app.config.globalProperties.$query = (query) => {
            return ax.get(query)
        }
        app.config.globalProperties.$post = (url, data) => {
            return ax.post(url, data, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        }
        app.config.globalProperties.$loadPage = (page, locale, setTitle = false) => {
            return new Promise((resolve, reject) => {
                var currentLocale = locale || "de"
                var pageObject = app.config.globalProperties.$store.state[page]
                if (typeof pageObject[currentLocale] !== 'object') {
                    if (document.querySelector("#data_" + pageObject[currentLocale])) {
                        var parsed = JSON.parse(document.querySelector("#data_" + pageObject[currentLocale]).innerHTML)
                        if (typeof parsed === 'object' && parsed !== null) {
                            pageObject[currentLocale] = parsed;
                            if (setTitle) {
                                document.title = pageObject[currentLocale].seo.title
                            }
                            resolve(pageObject[currentLocale])
                        }
                        else {
                            app.config.globalProperties.$query("page/" + pageObject[currentLocale]).then(e => {
                                pageObject[currentLocale] = e.data;

                                if(process.env.NODE_ENV == "development")
                                    console.log(e.data)
                                resolve(pageObject[currentLocale])
                            }).catch(error => {
                                console.log(error)
                            })
                        }
                    }
                    else {
                        app.config.globalProperties.$query("page/" + pageObject[currentLocale]).then(e => {
                            pageObject[currentLocale] = e.data;
                            if(process.env.NODE_ENV == "development")
                                console.log(e.data)
                            resolve(pageObject[currentLocale])
                        })

                    }
                }
                else {
                    resolve(pageObject[currentLocale])
                }
            })

        }
    }
}
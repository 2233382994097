import { createStore } from 'vuex'
import LazyLoad from "vanilla-lazyload"

import page_ids from "../page_ids"

export default (sitestructure) => {
  if (process.env.NODE_ENV == "development")
    console.log(page_ids, sitestructure)
  return createStore({
    state: {
      lazy: new LazyLoad(),
      ...page_ids,
      init_ids: JSON.parse(JSON.stringify(page_ids)),
      menuOpen: false,
      sitestructure
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
  })
}
